import { isStringPresent, ConfigError } from './helper'

import {
  ADMIN_HOST,
  ANALYTICS_ENABLED,
  COOKIE_SECURE,
  COOKIE_FUNCTIONAL_DOMAIN,
  COOKIE_SHARED_DOMAIN,
  ENVIRONMENT,
  RELEASE,
  PLAUSIBLE_DOMAINS,
  PLAUSIBLE_CLIENT_API_HOST,
  PLAUSIBLE_CLIENT_API_PATH,
  SENTRY_CLIENT_DSN,
  SENTRY_EDGE_DSN,
  WEBAPP_HOST,
} from './ClientValues'

if (!isStringPresent(ADMIN_HOST)) {
  throw new ConfigError('NEXT_PUBLIC_ADMIN_HOST missing')
}

if (!isStringPresent(COOKIE_FUNCTIONAL_DOMAIN)) {
  throw new ConfigError('NEXT_PUBLIC_COOKIE_FUNCTIONAL_DOMAIN missing')
}

if (!isStringPresent(COOKIE_SHARED_DOMAIN)) {
  throw new ConfigError('NEXT_PUBLIC_COOKIE_SHARED_DOMAIN missing')
}

if (!isStringPresent(ENVIRONMENT)) {
  throw new ConfigError('NEXT_PUBLIC_ENVIRONMENT missing')
}

if (!isStringPresent(SENTRY_CLIENT_DSN)) {
  throw new ConfigError('NEXT_PUBLIC_SENTRY_CLIENT_DSN missing')
}

if (!isStringPresent(SENTRY_EDGE_DSN)) {
  throw new ConfigError('NEXT_PUBLIC_SENTRY_EDGE_DSN missing')
}

if (!isStringPresent(WEBAPP_HOST)) {
  throw new ConfigError('NEXT_PUBLIC_WEBAPP_HOST missing')
}

const ClientConfig = {
  ADMIN_HOST,
  ANALYTICS_ENABLED,
  COOKIE_SECURE,
  COOKIE_FUNCTIONAL_DOMAIN,
  COOKIE_SHARED_DOMAIN,
  ENVIRONMENT,
  PLAUSIBLE_DOMAINS: PLAUSIBLE_DOMAINS ?? 'id.vayapin.com,collect.vayapin.com',
  PLAUSIBLE_CLIENT_API_HOST: PLAUSIBLE_CLIENT_API_HOST ?? 'https://vaya-plau.vayapin.workers.dev',
  PLAUSIBLE_CLIENT_API_PATH: PLAUSIBLE_CLIENT_API_PATH ?? '/vayaplau/event',
  RELEASE,
  SENTRY_CLIENT_DSN,
  SENTRY_EDGE_DSN,
  WEBAPP_HOST,
}

export default ClientConfig
