import { isBooleanTrue } from './helper'

export type Environment = 'development' | 'preview' | 'staging' | 'production' | string

export const ADMIN_HOST = process.env.NEXT_PUBLIC_ADMIN_HOST
export const ANALYTICS_ENABLED = isBooleanTrue(process.env.NEXT_PUBLIC_ANALYTICS_ENABLED ?? 'false')
export const COOKIE_SECURE = isBooleanTrue(process.env.NEXT_PUBLIC_COOKIE_SECURE ?? 'true')
export const COOKIE_FUNCTIONAL_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_FUNCTIONAL_DOMAIN
export const COOKIE_SHARED_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_SHARED_DOMAIN
export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT as Environment
export const RELEASE = process.env.NEXT_PUBLIC_RELEASE
export const SENTRY_CLIENT_DSN = process.env.NEXT_PUBLIC_SENTRY_CLIENT_DSN
export const SENTRY_EDGE_DSN = process.env.NEXT_PUBLIC_SENTRY_EDGE_DSN
export const WEBAPP_HOST = process.env.NEXT_PUBLIC_WEBAPP_HOST
export const PLAUSIBLE_DOMAINS = process.env.NEXT_PUBLIC_PLAUSIBLE_DOMAINS
export const PLAUSIBLE_CLIENT_API_HOST = process.env.NEXT_PUBLIC_PLAUSIBLE_CLIENT_API_HOST
export const PLAUSIBLE_CLIENT_API_PATH = process.env.NEXT_PUBLIC_PLAUSIBLE_CLIENT_API_PATH


